module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.mobotixonline.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/e.png","name":"Mobotix Online | EnTTia","short_name":"Mobotix Online","scope":"/","start_url":"https://www.mobotixonline.com","background_color":"#A5B4FC","theme_color":"#6366F1","display":"standalone","cache_busting_mode":"none","shortcuts":[{"name":"Cámaras de exterior","short_name":"Cámaras exteriores","description":"Cámaras de exterior inteligentes para videovigilancia y seguridad","url":"/camaras/exterior?source=pwa"},{"name":"Cámaras de interior","short_name":"Cámaras interiores","description":"Cámaras de interior inteligentes para videovigilancia y seguridad","url":"/camaras/interior?source=pwa"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icon*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-TCPYM2T76V","cookieName":"mobotix-online-GA","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-TRPH49X","cookieName":"mobotix-online-GTAG","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
