import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

/// Fuente
// https://github.com/fontsource/fontsource/tree/main/packages/roboto
// npm install @fontsource/roboto

//import "@fontsource/roboto";
import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "@fontsource/roboto/latin-700.css";

/// Estilo
import "./src/css/index.css";

// Establece los event listeners como pasivos
(function () {
    if (typeof EventTarget !== "undefined") {
        let func = EventTarget.prototype.addEventListener;
        EventTarget.prototype.addEventListener = function (type, fn, capture) {
            this.func = func;
            if (typeof capture !== "boolean") {
                capture = capture || {};
                capture.passive = false;
            }
            this.func(type, fn, capture);
        };
    };
}());

/// reCaptcha v3
const recaptchaPublicKey = "6LehcxQdAAAAAPu1081q9wVLo7nX-UHsKpSXaSSz";

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaPublicKey}>
            {element}
        </GoogleReCaptchaProvider>
    )
};